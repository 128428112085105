import React, { useState } from 'react';
import {DayPickerSingleDateController} from 'react-dates';
import { IntlShape } from 'react-intl';
import moment from "moment";
import classNames from 'classnames';

import {FieldTextInput, IconArrowHead, Modal} from "../../components";
import { manageDisableScrolling } from "../../storage/slices/ui";
import {FormattedMessage} from "../../util/reactIntl";

import css from './DiscountCalendarModal.module.css';
import calendarCss from '../../forms/EditListingAvailabilityForm/ManageAvailabilityCalendar.module.css'
import CustomDiscountSelectModal from '../../forms/EditListingPricingForm/CustomDiscountSelectModal';
import { discountOptions } from '../../util/discountConstants';

interface DiscountCalendarModalProps {
  visibility: boolean,
  handleDayPick: (date: string, unformatted: any) => void,
  handleModalSubmitWithoutDays: (discountValue: any) => void,
  handleModalSubmitWithDays: (discountValue: any) => void,
  turnOffDiscount: (discountValue: any) => void,
  toggleDiscountModalVisibility: () => void,
  listing: any,
  selectedDates: string[],
  date: any,
  onFocusChange: () => void,
  focused: boolean,
  isDatesChanged: boolean,
  intl: IntlShape,
  onUpdateListing: (values: any) => void,
}

const DiscountCalendarModal = ({
  visibility,
  listing,
  date,
  focused,
  selectedDates,
  isDatesChanged,
  handleDayPick,
  handleModalSubmitWithoutDays,
  handleModalSubmitWithDays,
  turnOffDiscount,
  toggleDiscountModalVisibility,
  onFocusChange,
  intl,
  onUpdateListing,
}: DiscountCalendarModalProps) => {
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [selectedDiscountValue, setSelectedDiscountValue] = useState();

  const toggleDiscountModal = (event?: any) => {
    event && event.preventDefault();
    setIsDiscountModalOpen(prevState => !prevState);
  }

  const onChooseDiscountValue = (discountValue: any) => {
    setSelectedDiscountValue(discountValue);
    const updatedValues = {
      id: listing.id.uuid,
      publicData: {
        discount: {
          value: discountValue || '',
          isActive: !!listing?.attributes.publicData.discount?.isActive,
        },
        isDiscountActive: !!listing?.attributes.publicData.isDiscountActive,
      },
    };
    onUpdateListing(updatedValues);
    toggleDiscountModal();
  }

  const setHighlightedDays = (e: any) => {
    if (!isDatesChanged) {
      return listing?.attributes.publicData.discount?.dates?.includes(moment(e._d).format("YYYY-MM-DD"));
    } else {
      return selectedDates.includes(moment(e._d).format("YYYY-MM-DD"));
    }
  };

  const discountPlaceholderMessage = intl.formatMessage({
    id: 'EditListingPricingForm.discountPlaceholder',
  });

  const isDiscountValid = (selectedDiscountValue && selectedDiscountValue !== '0')
    || (listing?.attributes.publicData.discount?.value && listing?.attributes.publicData.discount?.value !== '0');

  return (
    <Modal
      id="ManageListingsPage.discountCalendarModal"
      isOpen={visibility}
      onClose={toggleDiscountModalVisibility}
      onManageDisableScrolling={manageDisableScrolling}
      usePortal
      containerClassName={css.modalContentContainer}
      withCloseButton={false}
      isOpenClassName={css.backgroundBlur}
      isClosedClassName={css.isClosed}
      scrollLayerClassName={css.scrollLayer}
    >
      {isDiscountModalOpen &&
        <CustomDiscountSelectModal
          id="discountSelector"
          name="discountSelector"
          options={discountOptions}
          initialSelectedDiscount={listing?.attributes.publicData.discount?.value}
          intl={intl}
          onChooseDiscountValue={onChooseDiscountValue}
          onCancel={toggleDiscountModal}
        />
      }
      <div className={css.modalTitle}>
        <FormattedMessage
          id="ManageListingsPage.discountModalTitle"
          values={{
            discountValue: listing?.attributes.publicData.discount?.value,
            listingTitle: listing?.attributes.title,
          }}
        />
      </div>
      <div className={css.contentContainer}>
        <DayPickerSingleDateController
          isFocused={true}
          date={date}
          numberOfMonths={1}
          onDateChange={(e: any) => {
            handleDayPick(moment(e._d).format("YYYY-MM-DD"), e);
          }}
          renderMonthElement={({ month }) => (
            <div className={calendarCss.monthElement}>
              <span className={calendarCss.monthString}>{month.format('MMMM YYYY')}</span>
            </div>
          )}
          navNext={(
            <div className={css.nextArrowWrapper}>
              <IconArrowHead size="small" direction="right" />
            </div>
          )}
          navPrev={(
            <div className={css.prevArrowWrapper}>
              <IconArrowHead size="small" direction="left" />
            </div>)}
          onFocusChange={onFocusChange}
          weekDayFormat="dd"
          focused={focused}
          initialVisibleMonth={null}
          isDayHighlighted={setHighlightedDays}
          hideKeyboardShortcutsPanel
          daySize={47}
        />
        <div className={css.buttonsContainer}>
          <label className={css.discountInputLabel}>
            <FormattedMessage id="EditListingPricingForm.discountLabel" />
            <div
              className={css.discountFieldContainer}
              onClick={(event) => toggleDiscountModal(event)}
            >
              <input
                type="text"
                readOnly
                value={listing?.attributes.publicData.discount?.value || '0'}
                placeholder={discountPlaceholderMessage}
              />
              <IconArrowHead className={css.discountFieldArrow} direction="down" />
            </div>
          </label>
          <button
            className={css.button}
            onClick={() => {
              if (!isDiscountValid) {
                toggleDiscountModal();
                return;
              }
              handleModalSubmitWithDays(selectedDiscountValue);
            }}
          >
            Apply selected days for the discount
          </button>
          <button
            className={css.button}
            onClick={() => {
              if (!isDiscountValid) {
                toggleDiscountModal();
                return;
              }
              handleModalSubmitWithoutDays(selectedDiscountValue);
            }}
          >
            Turn on the discount without specified days
          </button>
          <button
            className={css.button}
            onClick={() => turnOffDiscount(selectedDiscountValue)}
          >
            Turn off the discount
          </button>
          <button
            className={classNames(css.button, css.cancelButton)}
            onClick={toggleDiscountModalVisibility}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DiscountCalendarModal;
