import React, { useState } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';

import css from './EditListingPricingForm.module.css';
import classNames from 'classnames';

interface CustomDiscountSelectModalProps {
  name: string;
  id: string;
  options: Array<{ label: string; key: string }>;
  initialSelectedDiscount: string;
  intl: IntlShape;
  onChooseDiscountValue: (discountValue: string) => void;
  onCancel: () => void;
}

const CustomDiscountSelectModal: React.FC<CustomDiscountSelectModalProps> = ({
  name = '',
  id = '',
  options = [],
  initialSelectedDiscount = '',
  intl,
  onChooseDiscountValue,
  onCancel,
}) => {
  const [selectedDiscount, setSelectedDiscount] = useState('');

  return (
    <div className={css.discountSelectModalOverlay}>
      <div className={css.discountSelectModal}>
        <h2 className={css.discountHeading}>
          <FormattedMessage id="EditListingPricingForm.discountLabel" />
        </h2>
        <p className={css.discountLabel}>
          <FormattedMessage id="EditListingPricingForm.pleaseSelectDiscount" />
        </p>
        {options && (
          <ul className={css.discountList}>
            {options.map(option => {
              const fieldId = `${id}.${option.key}`;
              return (
                <li key={fieldId}>
                  <label className={css.discountFieldRadioButton}>
                    <input
                      type="radio"
                      className={css.discountFieldRadioInput}
                      name={name}
                      value={option.key}
                      onClick={event => setSelectedDiscount(event.currentTarget.value)}
                      defaultChecked={initialSelectedDiscount === option.key}
                    />
                    <div className={css.discountFieldRadioBox} />
                    <span className={css.discountFieldRadioLabel}>
                      {option.label}
                    </span>
                  </label>
                </li>
              );
            })}
          </ul>
        )}
        <div className={css.discountSelectBottomWrapper}>
          <div
            className={css.discountSelectButton}
            onClick={onCancel}
          >
            <FormattedMessage id="EditListingPricingForm.cancelButton" />
          </div>
          <div
            className={classNames(css.discountSelectButton, css.discountSelectButtonHighlight)}
            onClick={() => onChooseDiscountValue(selectedDiscount)}
          >
            <FormattedMessage id="EditListingPricingForm.chooseButton" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomDiscountSelectModal;
